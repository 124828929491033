export interface PageItem {
    pageNameCN: string;
    pageNameEN: string;
    link: string;
    nav:boolean;
    data:string;
    component:string;
    headVideo?:string;
}

import {isMobile} from '@/isMobile';

export const authURL = 'https://auth.huamei2001.com'
// export const authURL = 'http://localhost:4005'

export const backEndURL = 'https://backend.huamei2001.com'
// export const backEndURL = 'http://localhost:4004'




export const pages = [
    {
        pageNameCN:'主页',
        pageNameEN:'Home',
        bigTitle:"<div>尽管问 PK ChatGPT</div>",
        pageSubTitle:"定制大模型助力酒店提升经营业绩",
        nav:false,
        component:'Home',
        link:'/home',
        data:'home',
        alias:'/',
        brightness:0.9,
        headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E9%A6%96%E9%A1%B5.jpg',
        tabs:[],
    },
    {
        pageNameCN:`为您\n服务`,
        pageNameEN:'Services',
        bigTitle:"<div>咨询服务+尽管问+华美云</div>",
        pageSubTitle:"全新平台为客户提供更多价值和更好服务",
        nav:true,
        component:'Services',
        link:'/services',
        data:'services',
        brightness: 0.8,
        headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E4%B8%BA%E6%82%A8%E6%9C%8D%E5%8A%A1.jpg',
        // '/temp/为您服务.jpg',
        // 'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E4%B8%BA%E6%82%A8%E6%9C%8D%E5%8A%A1.jpeg',
        tabs:['新建\n酒店','改建\n酒店','开业\n酒店','酒店\n集团','酒店\n地产',`酒店+`,],
    },
    {
        pageNameCN:'成功\n故事',
        pageNameEN:'Success Stories',
        bigTitle:"<div>听说华美帮客户省了两个亿</div>",
        pageSubTitle:"卖住宅建酒店的开发模式必须改变了",
        nav:true,
        component:'Cases',
        link:'/cases',
        data:'cases',
        brightness: 1.1,
        // headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E6%88%90%E5%8A%9F%E6%95%85%E4%BA%8B.jpg',
        headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E6%88%90%E5%8A%9F%E6%95%85%E4%BA%8B.jpg',
        tabs:['典型\n客户','项目\n分布','项目\n分类', ],
    },
    {
        pageNameCN:'华美\n云',
        pageNameEN:'Our Research',
        bigTitle:"<div>用经营数据评估业绩表现，</div><div>然后呢？</div>",
        pageSubTitle:"<div>《华美云—全球酒店经营提升案例库》助力酒店快速采取有效行动</div>",
        nav:true,
        component:'Research',
        link:'/research',
        data:'research',
        brightness:0.8,
        pageHeadArticle:'https://huamei2001.com/article?id=3444',
        // headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E5%8D%8E%E7%BE%8E%E4%BA%91.jpg',
        headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E5%8D%8E%E7%BE%8E%E4%BA%91.jpg',
        tabs:['登录\n华美云', '查酒店', '品牌墙', '环酒历', '热点\n报告','实用\n导航',],
    },
    {
        pageNameCN:'尽管\n问',
        pageNameEN:'Jinguanwen',
        bigTitle:"<div>用经营数据评估业绩表现，</div><div>然后呢？</div>",
        pageSubTitle:"<div>《华美云—全球酒店经营提升案例库》助力酒店快速采取有效行动</div>",
        nav:true,
        component:'',
        link:'/jinguanwen',
        externalLink:'https://hotelmgmt.jinguanwen.com',
        data:'Jinguanwen',
        brightness:0.6,
        headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E5%8D%8E%E7%BE%8E%E4%BA%91.jpg',
        // tabs:['首席知识官\n赵焕焱','热点\n专题','行业\n数据','城市\n数据','品牌\n数据','管理集团\n及品牌','最佳\n新酒店','绿色\n酒店'],
        // tabs:['首席知识官\n赵焕焱','热点\n专题','城市\n数据','品牌\n数据','管理集团\n及品牌','最佳\n新酒店','绿色\n酒店'],
        tabs:['登录\n华美云', '查酒店', '品牌墙', '环酒历', '热点\n报告','实用\n导航',],
    },
    {
        pageNameCN:'关于\n华美',
        pageNameEN:'About Us',
        bigTitle:"<div>减少浴缸！</div>",
        pageSubTitle:"在所有客房配置浴缸是熟视无睹的巨大浪费",
        nav:true,
        component:'AboutUs',
        link:'/about-us',
        data:'about',
        brightness:0.6,
        headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%A4%B4%E5%9B%BE/%E5%85%B3%E4%BA%8E%E5%8D%8E%E7%BE%8E.jpg',
        // headImage:'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2023/官网图片素材20230214/打碎铁笼.jpg',
        tabs:['华美\n简介','ESG','员工\n与文化','加入\n华美',],
    },
    {
        pageNameCN:'联系\n我们',
        pageNameEN:'Contact Us',
        bigTitle:"<div>联系我们，</div><div>一起创造新酒店！</div>",
        titleColor:'#fff',
        shadowColor:'transparent',
        nav:true,
        component:'ContactUs',
        link:'/contact-us',
        data:'contact',
        headImage:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/website_pic.jpg',
        tabs:[]
    },
]